.styleLiceRecd {
  color: #0d6efd;
  cursor: pointer;
  text-decoration: none;
}
.styleLiceRecd:hover{
  text-decoration: underline;
}

.styleBreadcrumb {
  color: #0d6efd;
  cursor: pointer;
  text-decoration: underline;
}

.toggleButtonInline{
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
}